<template>
  <a-modal
    title="复制 — 区域商品,推荐位,轮播图"
    width="50%"
    :visible="visible"
    :confirmLoading="confirmLoading"
    :destroyOnClose="true"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-row :gutter="24">
        <a-col :md="24" :sm="24">
          <a-form :form="form">
            <a-form-item
              label="新区域"
              extra="选择需要复制区域商品,推荐位,轮播图的新区域"
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
              has-feedback
            >
              <a-select
                
                v-decorator="['newAreaId', { rules: [{ required: true, message: '请选择区域！' }] }]"
                allow-clear
                placeholder="请选择"
              >
                <a-select-option v-for="(item, index) in dataList" :key="index" :value="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
    </a-spin>
  </a-modal>
</template>

<script>
import { areaCopyGoods, areaPage } from '@/api/modular/mallLiving/area' //编辑和详情接口
export default {
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      labelCol_1: {
        xs: { span: 24 },
        sm: { span: 3 },
      },
      wrapperCol_1: {
        xs: { span: 24 },
        sm: { span: 20 },
      },
      id: '',
      dataList: [], //父级列表
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
    }
  },
  mounted() {
    this.getAreaList()
  },
  methods: {
    // 初始化方法
    init(record) {
      //下面是正常的初始化
      this.id = record.id
      this.visible = true
    },
    getAreaList() {
      let query = {
        queryParam: {},
        page: {
          pageNo: 1,
          pageSize: 1000000,
        },
      }
      areaPage(query).then((res) => {
        if (res.success) {
          this.dataList = res.data.rows
        }
      })
    },
    handleSubmit() {
      this.confirmLoading = true
      this.form.validateFields((errors, values) => {
        if (!errors) {
          // 调取编辑接口
          areaCopyGoods({ areaId: this.id, ...values }).then((res) => {
            if (res.success) {
              this.$message.success('复制成功！')
              this.$emit('ok', values)
              this.confirmLoading = false
              this.handleCancel()
            } else {
              setTimeout(() => {
                this.confirmLoading = false
              }, 600)
            }
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel() {
      this.visible = false //关闭对话框
      this.confirmLoading = false
      this.animationList = []
      this.form.resetFields() //重置表单
    },
  },
}
</script>
<style scoped>
.flex {
  display: flex;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
